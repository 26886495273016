<template>
  <div class="wrapper-page">
    <div class="ex-page-content text-center">
      <div class="text-error">
        <i class="fad fa-shield-check fa-10x"></i>
        <h1 class="text-info">
          401
        </h1>
      </div>
      <h2>Who0ps! No tienes permisos</h2><br>
      <p class="text-muted">
        No tienes suficientes privilegios para ingresar al módulo.
      </p>
      <br>
      <a
        class="btn btn-default waves-effect waves-light"
        href="#"
        @click.prevent="onReturnDashboard"
      >
        Regresar al HOME</a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Router from '@/router'

export default {
  data() {
    return { error: '' }
  },
  methods: {
    onReturnDashboard() {
      const _this = this
      if (+_this.setting.roleId === 4) {
        Router.push({ name: 'HomeSellers' })
      } else if (+_this.setting.roleId === 3) {
        Router.push({ name: 'HomeSupport' })
      } else {
        Router.push({ name: 'Home' })
      }
    },
  },
  computed: {
    ...mapGetters(['setting']),
  },
}
</script>
